<template>
  <div style="width:100%">
   <div class="sedate">
     <el-date-picker
       @change="getEmps"
       :clearable="false"
       v-model="date"
       value-format="yyyy-MM-dd"
       type="date"
       placeholder="选择日期">
     </el-date-picker>
     <img src="../../assets/sx.png" class="shuaxin" @click="shuaxin">
   </div>
    <div style="padding-bottom: 100px">
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="gw.paAttribute" v-for="gw in gws"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="yyinfo" :style="innerHeight">
        <div class="yytimes">
          <div class="square-box">
            <div class="riqi">日期</div>
            <div class="dianyuan">店员</div>
          </div>
          <div class="time-items" v-for="time in times">
              {{time.time}}
          </div>
        </div>
        <div v-for="emp in emps">
          <div class="emps-names" >
            {{emp.empName}}
          </div>
            <div class="emps-times"  v-for="time in emp.empTimes">
              <div class="yymbinfo1" v-if="!time.mbShow">
                <span class="yyzt2" style="background-color: #eeeeee;" v-if="time.status!=4&&time.expire">{{time.status}}</span>
                <span class="yyzt2" style="text-align: center;cursor: pointer" v-if="time.status!=4&&!time.expire" @click="onOccupation(emp,time)"></span>
                <span class="yyzt2" style="text-align: center;cursor: pointer" v-if="time.status==4" @click="cancelOccupation(time)">已占用</span>
              </div>
              <div class="yymbinfo" :style="time.styleStr" v-if="time.mbShow" @click="tcyyinfo(time)">
                <span class="yyname" >{{time.name}}</span>
                <span class="yyphone" v-if="time.phone">{{time.phone}}</span>
                <span class="yyzt" v-if="time.status==1">状态: 已预约</span>
                <span class="yyzt" v-if="time.status==2">状态: 已到店</span>
                <span class="yyzt" v-if="time.status==3">状态: 已结算</span>
                <span class="yyzt" v-if="time.status==10">状态: 服务中</span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预约信息提示框 -->
    <el-dialog title="预约详情" :visible.sync="yyVisible" width="500px" center>
      <div class="yyxiangqing">
        <div class="yymbname" >
          <div class="yy-mb-name" :class="{'active':mbTab==index}"  v-for="(item,index) in yys" @click="setmb(item,index)">
            <i class="el-icon-s-custom"></i>
            <span >{{item.mbName}}</span>
          </div>
        </div>
        <div class="yy-mb-info">
         <div class="spi">
           状态:
             <el-tag size="small" v-if="mb.yyStatus==1" type="warning">已预约</el-tag>
             <el-tag size="small" v-if="mb.yyStatus==2" type="info">已到店</el-tag>
             <el-tag size="small" v-if="mb.yyStatus==3" type="success">已结算</el-tag>
         </div>
         <span class="spi">时间: {{mb.yyTime}}</span>
         <span class="spi">项目:
           <el-tag size="small">{{mb.bq}}</el-tag>
         </span>
         <span>备注</span>
         <div class="beizhu">
           {{mb.note}}
         </div>
          <div class="czbtn">
            <el-button size="small" class="quxyy" v-if="mb.yyStatus!=3" type="danger" @click="toDel(mb)">取消预约</el-button>
            <el-button size="small" class="qukd" v-if="mb.yyStatus==2" type="primary" @click="toKd(mb)">去开单</el-button>
            <el-button size="small" class="ckyh" type="info" @click="toMbInfo(mb)">查看用户</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 取消占用提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="500px" center>
      <div class="del-dialog-cnt" style="text-align: center;">是否取消？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 占用提示框 -->
    <el-dialog title="提示" :visible.sync="zyVisible" width="500px" center>
      <div class="del-dialog-cnt" style="text-align: center;">是否占用？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="zyVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="zyMethod">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";

  export default {
    name: 'dashboard',
    data() {
      return {
        // date:'2025-03-13',
        date:'',
        activeName:0,
        gw:{},
        gws:[],

        times:[],

        emps:[],
        yys:[],
        mb:{},
        mbTab:0,
        yyVisible:false,

        delId:'',
        delVisible:false,
        zy:{},
        zyVisible:false,
        timeBlocks: [],
        showModal: false,
        reservationTime: '',
        reservationProject: '',
        reservationNote: '',

        innerHeight:'',
      }
    },

    async created() {
      this.innerHeight ='height:'+(window.innerHeight/1.3)+'px'
      this.formatDate()
      await this.getGws()
      this.getTimes();
      this.getEmps();
    },
    methods: {
      shuaxin(){
        this.getEmps()
      },
      async getGws() {
        let res = await this.$get("/sw/getSwPlabsList", {token: getToken()});
        this.gws=res.plabsList
        if (this.gws.length>0){
          this.gw=this.gws[0]
        }
      },
      async getTimes() {
        let res = await this.$get("/sw/getSwAppointmentTimes", {token: getToken()});
        this.times=res.times
      },
      async getEmps() {
        let data={
          token: getToken(),
          station:this.gw.station,
          date:this.date,
        }
        let res = await this.$get("/sw/getEmpAppointmentTimes",data);
        this.emps=res.emps
      },

      setmb(item,index){
        this.mb=item
        this.mbTab=index
      },
      tcyyinfo(item){
        if (item.status==4){
          return;
        }
        this.getMbyys(item.yyIds)
        this.yyVisible=true
      },
      toDel(item) {
        this.delId = item.id
        this.delVisible = true
      },
      toKd(item){
        if (item.id&&item.yyStatus==2) {
          this.$router.push(`/cashiers/billing?memberId=` + item.memberId+'&yyId='+item.id);
        }
      },
      toMbInfo(item) {
        if (item.memberId) {
          this.$router.push(`/customers/memberInfo?memberId=` + item.memberId+"&yyId="+item.id+'&yyStatus='+item.yyStatus);
        }
      },
      async getMbyys(yyIds){
        if (yyIds.length<=0){
          this.$message.error("预约人为空")
          return ;
        }
        let res = await this.$post("/sw/getMbAppointmentInfo",{yyIds:JSON.stringify(yyIds)});
        this.yys=res.yys
        if(this.yys.length>0){
          this.mb=this.yys[0]
        }
      },
      onOccupation(emp,time){
        this.zy={
          empId:emp.id,
          empName:emp.empName,
          deptId:emp.deptId,
          station:emp.station,
          time:time.time,
          date:this.date,
        }
        this.zyVisible = true
      },
      cancelOccupation(item){
        if (item.yyIds.length>0){
          this.delId=item.yyIds[0]
        }
         this.delVisible = true
      },

      async delMethod(){
        if (!this.delId){
          this.$message.error("数据异常!")
          return ;
        }
        let res = await this.$post("/sw/delAppointmentRecord",{id:this.delId});
        if (res.code==200){
          this.yyVisible=false
          this.delVisible = false
          this.getEmps()
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      },

      async zyMethod(){
        if (!this.zy.empId){
          this.$message.error("数据异常!")
          return ;
        }
        let res = await this.$post("/sw/swOccupy",this.zy);
        if (res.code==200){
          this.zyVisible = false
          this.getEmps()
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      },

      handleClick(val){
        this.gw=this.gws[val.index]
        this.getEmps()
      },
      formatDate() {
        let date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        this.date = `${year}-${month}-${day}`;
      }
    }
  }

</script>


<style scoped>
.sedate{
  padding: 20px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.shuaxin{
  width: 35px;
  height: 35px;
  margin-left: 60px;
}
.yyinfo{
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
}
.yytimes{
  display: flex;
  flex-direction: column;
}
.square-box{
  width: 128px;
  height: 88px;
  border: #e5e5e5 solid 1px;
  display: flex;
  flex-direction: row;
  background:linear-gradient(34.5deg,transparent 49.5%, #e5e5e5 49.5%, #e5e5e5 50.5%,transparent 50.5%);
}
.dianyuan{
  width: 50%;
  text-align: center;
  position: relative;
  line-height: 60px;
  height: 100px;
}
.riqi{
  width: 50%;
  text-align: center;
  position: relative;
  line-height: 140px;
  height: 88px;
}
.time-items{
  width: 128px;
  height: 100px;
  border: #e5e5e5 solid 1px;
  border-top: 0px;
  text-align: center;
  line-height: 88px;
}
.emps-names{
  width: 188px;
  height: 88px;
  border: #e5e5e5 solid 1px;
  border-left: 0px;
  text-align: center;
  line-height: 88px;
}

.emps-times{
  width: 188px;
  height: 88px;
  border: #e5e5e5 solid 1px;
  border-top: 0px;
  border-left: 0px;
  text-align: center;
  line-height: 88px;
}
.yymbinfo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 88px;
  padding: 0 16px;
  text-align: left;
  font-size: 10px;
}
.yymbinfo1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 88px;
  text-align: left;
  font-size: 10px;
}
.yyname{
  height: 25px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.yyphone{
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.yyzt{
  height: 20px;
  line-height: 20px;
}
.yyzt1{
  height: 20px;
  line-height: 20px;
  color: #5E5E5E;
}
.yyxiangqing{
  display: flex;
  flex-direction: row;
}
.yymbname{
  border-right: #e5e5e5 solid 1px;
  padding-right: 10px;
}
.yy-mb-name{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 0 8px;
  border-radius: 3px;
}
.yy-mb-name span{
  display: block;
  width: 88px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 28px;
  padding-left: 5px;
  line-height: 28px;
}
.yy-mb-info{
  width: 100%;
  padding: 10px 10px 0 10px;
  height: 400px;
  display: flex;
  flex-direction: column;
}
.yy-mb-info .spi{
  margin-bottom: 18px;
}
.beizhu{
  margin-top: 5px;
  height: 88px;
  background-color: #E8E8E8;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}
.active{
  background-color: #E67E70;
  color: #fff;
}


.czbtn{
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  height: 200px;
}
.ckyh{
  width: 100%;
  background-color: #58516E;
  margin-bottom: 10px;
  margin-left: 0px;
}

.qukd{
  width: 100%;
  margin-left: 0px;
  margin-bottom: 10px;
}

.quxyy{
  width: 100%;
  margin-left: 0px;
  margin-bottom: 10px;
}
.yyzt2{
  width: 100%;
  display: block;
  height: 88px;
}

</style>
